import React, {useEffect, useState} from "react";
import {
    TextField,
    Grid, ThemeProvider,
    Accordion,
    AccordionSummary,
    AccordionDetails

} from "@mui/material";
import ResultsModal from "./components/ResultsModal"
import HeadBanner from "./components/HeadBanner";
import HistoricalSection from "./components/HistoricalSection/HistoricalSection";
import CustomSection from "./components/CustomSection/CustomSection";
import Description from "./components/Description/Description";
import {themeOptions} from "./resources/themeOptions";
import {calculate} from './common/calculate'
import CustomStepper from "./CustomStepper/CustomStepper";
import {ExpandMore} from "@mui/icons-material";

const Main = () => {

    const steps = [
        "1. Balance",
        "2. Parameters",
        "3. Results"
    ]

    const [bankBalance, setBankBalance] = useState(1000);
    const [results, setResults] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [isCustom, setIsCustom] = useState(false)
    const [payload,setPayload]=useState(null)


    const sendButtonClick = () => {


        let tempPayload = {
            "bankBalance": bankBalance,
            "yearInterestPair": payload
        };

        if (payload !== null) {
            let res = calculate(tempPayload);
            setResults(res)
        }
    };
    useEffect(() =>{

        sendButtonClick()
    },[payload])



    return (
        <ThemeProvider theme={themeOptions}>
            <HeadBanner/>
            <div className="spacerMargin">
                <div className="main">
                    <Description/>

                    <CustomStepper
                        setCurrentStep={(newVal) => setCurrentStep(newVal)}
                        steps={steps}
                        currentStep={currentStep}
                    >

                        {
                            currentStep === 0 && (

                                <div>
                                    <div className="row">
                                        <div style={{width:"100%"}}>
                                            <p>Select a starting balance:</p>
                                        </div>
                                        <div style={{width:"100%"}} className="label">
                                            <TextField id="standard-basic"
                                                       label="Initial Bank Balance"
                                                       value={bankBalance}
                                                       onChange={(e) => setBankBalance(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                            )
                        }

                        {
                            currentStep === 1 && (
                                <Grid container>
                                    <p>Choose historical data or create your own custom dataset:</p>

                                    <Accordion disabled={!isCustom} expanded={!isCustom} style={{width:"100%", backgroundColor: "#e5f5dc"}}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMore
                                                onClick={() => setIsCustom(false)}/>}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            Historical Data
                                        </AccordionSummary>
                                        <AccordionDetails >
                                            <HistoricalSection
                                                setPayload={(newPayload) => {setPayload(newPayload)}}
                                                isCustom={isCustom}
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion disabled={isCustom} expanded={isCustom} style={{width:"100%", backgroundColor: "#e5f5dc"}}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMore onClick={() => setIsCustom(true)}/>}
                                            aria-controls="panel2-content"
                                            id="panel2-header"
                                        >
                                            Custom Data
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {
                                                isCustom && (
                                                    <CustomSection
                                                        setPayload={(newPayload) => {setPayload(newPayload)}}
                                                        isCustom={isCustom}
                                                    />
                                                )
                                            }

                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            )
                        }

                        {
                            currentStep === 2 && (
                                <div className="row">
                                    {
                                        results !== null && (
                                            <ResultsModal content={results}/>

                                        )
                                    }
                                </div>
                            )
                        }
                    </CustomStepper>

                </div>
            </div>
        </ThemeProvider>
    );
};

export default Main;
