import {IconButton, Table, TableBody, TableRow} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import React from "react";
import Iteration from "./ResultsIteration";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Graph from "./Graph/Graph";
import Grid2 from "@mui/material/Unstable_Grid2";

const ResultsModal = ({content}) => {

    let bankBankBalance = content.initialBankBalance;
    let finalBankBalance = content.finalBankBalance;
    let iterations = content.iterations;

    let labels = iterations.map((iter) => {
        return iter.year
    })

    let dataSet = iterations.map((iter) => {
        return iter.newBankBalance
    })

    return(
        <>
            <Grid2 container>

                <Grid2 item size={12} style={{width: "100%"}}>
                    <h2 style={{width:"100%"}}>Final Balance: {parseFloat(finalBankBalance).toFixed(2)}</h2>
                </Grid2>
                <Grid2 item size={12} style={{width: "100%"}}>
                    <p>Starting Bank Balance: {bankBankBalance}</p>
                </Grid2>
                <Grid2 item size={12} style={{width: "100%"}}>
                    <Graph labels={labels} dataset={dataSet}/>
                </Grid2>
                <Grid2 style={{ maxHeight: "50vh",
                    overflow: "auto",
                    width: "100%"}} item size={12}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <th>Year</th>
                                <th>Start Balance</th>
                                <th>Interest Rate</th>
                                <th>Annual Interest</th>
                                <th>End Balance</th>
                            </TableRow>

                            {
                                iterations.map((iteration, index) => {
                                    return(
                                        <Iteration
                                            key ={index}
                                            year = {iteration.year}
                                            initialBalance={iteration.initialBalance}
                                            interestRate={iteration.interestRate}
                                            annualInterest={iteration.annualInterest}
                                            newBankBalance={iteration.newBankBalance}
                                        />
                                    )
                                })}
                        </TableBody>
                    </Table>
                </Grid2>
            </Grid2>



        </>
    );
};
export default  ResultsModal;
